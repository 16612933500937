import Box from '@mui/material/Box';
import { JestTestId } from "src/constants/tests";
import { MOBILE_SUBDOMAIN } from "src/constants/ui";
import { themeV2 } from "src/theme/mysherpas-theme-option";
import { getInitials } from "src/utils/get-initials";
import { getSubdomainFromUrl } from "src/utils/url/get-subdomain-from-url";

const baseSx = {
    width: 64,
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    overflow: 'hidden',
    objectFit: 'contain',
    borderRadius: '50%',
}

interface AuthLogoProps {
    logo?: string;
    name: string;
}

export const CompanyLogo = (props: AuthLogoProps) => {
    const subdomain = typeof document !== 'undefined' ? getSubdomainFromUrl(document.location.href) : '';

    // If we are in the mobile subdomain, we don't want to show the logo
    if (MOBILE_SUBDOMAIN === subdomain) {
        return null;
    }

    if (props.logo) {
        return <Box
            sx={{
                ...baseSx,
                boxShadow: '4px 4px 12px 0px rgba(0, 0, 0, 0.10)',
                padding: .5,
                border: `1px solid ${themeV2.neutral.grey.default}`,
            }}
            component='img'
            alt="Log-in icon"
            src={props.logo} />
    }

    return <Box
        className='auth-logo'
        data-testid={JestTestId.AuthLogoImage}
        sx={{
            ...baseSx,
            fontSize: 24,
            cursor: 'default',
            pointerEvents: 'none',
            margin: '0 auto',
            backgroundColor: themeV2.colors.yellow[100]
        }}
    >
        {getInitials(props.name)}
    </Box>;
}