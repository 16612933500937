import Typography from '@mui/material/Typography';
import { AppleIcon } from 'src/components/apple-icon';
import { useSocialLogin } from "src/hooks/use-social-login";

import { AuthButton } from "./auth-button";

interface AppleButtonProps {
    title?: string;
}

export const AppleButton = (props: AppleButtonProps) => {
    const { title } = props;
    const { appleLink } = useSocialLogin();

    return (<AuthButton size='large' href={appleLink}>
        <AppleIcon />
        <Typography fontWeight={500} fontSize={16}>
            {title ?? 'Sign in with Apple'}
        </Typography>
    </AuthButton>)
}