import Typography from '@mui/material/Typography';
import { GoogleIcon } from 'src/components/google-icon';
import { useSocialLogin } from "src/hooks/use-social-login";

import { AuthButton } from "./auth-button";

interface GoogleButtonProps {
    title?: string;
}

export const GoogleButton = (props: GoogleButtonProps) => {
    const { title } = props;
    const { googleLink } = useSocialLogin();

    return (<AuthButton size='large' href={googleLink}>
        <GoogleIcon />
        <Typography fontWeight={500} fontSize={16}>
            {title ?? 'Sign in with Google'}
        </Typography>
    </AuthButton>)
}