import Box from '@mui/material/Box';
import { AppleButton } from 'src/components/auth/apple-button';
import { GoogleButton } from 'src/components/auth/google-button';
import { MicrosoftButton } from 'src/components/auth/microsoft-button';
import { YahooButton } from 'src/components/auth/yahoo-button';

interface Props {
    mode: 'login' | 'register';
}

export const SocialAuthButtons = (props: Props) => {
    const { mode } = props;

    return <Box sx={{
        mt: 6,
        gap: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > a': {
            overflow: 'hidden',
            gap: 0,
            transition: 'gap 0.3s ease',
        },
        '& > a > p': {
            whiteSpace: 'nowrap',
            width: 0,
            opacity: 0,
            transition: 'opacity 0.3s ease, width 0.3s ease',
            animationFillMode: 'forwards',
        },
        '& > a:hover': {
            transition: 'gap 0.3s ease',
            gap: 2,
            '& > p': {
                width: 160,
                opacity: 1,
                transition: 'opacity 0.3s ease, width 0.3s ease',
                animationFillMode: 'forwards',
            }
        },
    }}>
        <GoogleButton title={mode === 'register' ? 'Sign up with Google' : undefined} />
        <MicrosoftButton title={mode === 'register' ? 'Sign up with Microsoft' : undefined} />
        <AppleButton title={mode === 'register' ? 'Sign up with Apple' : undefined} />
        <YahooButton title={mode === 'register' ? 'Sign up with Yahoo' : undefined} />
    </Box>
}