import * as React from "react"

export const MicrosoftIcon = (props) => (
    <svg
        width={21}
        height={21}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21 21"
        preserveAspectRatio="xMidYMid meet"
        {...props}
    >
        <path d="M10 1H1v9h9V1Z" fill="#F25022" />
        <path d="M10 11H1v9h9v-9Z" fill="#00A4EF" />
        <path d="M20 1h-9v9h9V1Z" fill="#7FBA00" />
        <path d="M20 11h-9v9h9v-9Z" fill="#FFB900" />
    </svg>
)