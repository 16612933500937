import { useRouter } from "next/router";
import { isDevelopmentMode } from 'src/utils';
import { isServer } from "src/utils/is-server";

const googleLink = isDevelopmentMode ? `http://localhost:5000/oauth2/authorize/google?redirect_uri=${isServer ? '' : window.location.origin}` : `/oauth2/authorize/google?redirect_uri=${isServer ? '' : window.location.origin}`;
const yahooLink = isDevelopmentMode ? `http://localhost:5000/oauth2/authorize/yahoo?redirect_uri=${isServer ? '' : window.location.origin}` : `/oauth2/authorize/yahoo?redirect_uri=${isServer ? '' : window.location.origin}`;
const appleLink = isDevelopmentMode ? `http://localhost:5000/oauth2/authorize/apple?redirect_uri=${isServer ? '' : window.location.origin}` : `/oauth2/authorize/apple?redirect_uri=${isServer ? '' : window.location.origin}`;
const microsoftLink = isDevelopmentMode ? `http://localhost:5000/oauth2/authorize/azuread?redirect_uri=${isServer ? '' : window.location.origin}` : `/oauth2/authorize/azuread?redirect_uri=${isServer ? '' : window.location.origin}`;

export const useSocialLogin = () => {
    const { query } = useRouter();

    const url = (typeof query.url === 'undefined' ? '' : query.url);
    return {
        googleLink: `${googleLink}&url=${url}`,
        yahooLink: `${yahooLink}&url=${url}`,
        appleLink: `${appleLink}&url=${url}`,
        microsoftLink: `${microsoftLink}&url=${url}`
    }
};