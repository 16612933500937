import type { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import type { LinkProps } from '@mui/material/Link';
import { themeV2 } from "src/theme/mysherpas-theme-option";

export type ButtonLinkProps = Omit<ButtonProps, "href"> & LinkProps;

export const AuthButton = (props: ButtonLinkProps) => {
    const { children, ...rest } = props;
    return (<Button
        {...rest}
        component='a'
        sx={{
            height: 56,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 1,
            gap: 2,
            color: 'text.primary',
            borderStyle: 'solid',
            borderColor: themeV2.neutral.grey.default,
            borderRadius: themeV2.shape.borderRadius.button,
            '& svg': {
                width: 24,
                height: 24,
            }
        }} >
        {children}
    </Button>)
}