import Typography from '@mui/material/Typography';
import { YahooIcon } from 'src/components/yahoo-icon';
import { useSocialLogin } from "src/hooks/use-social-login";

import { AuthButton } from "./auth-button";

interface YahooButtonProps {
    title?: string;
}

export const YahooButton = (props: YahooButtonProps) => {
    const { title } = props;
    const { yahooLink } = useSocialLogin();

    return (<AuthButton
        size='large'
        href={yahooLink}>
        <YahooIcon />  <Typography fontWeight={500} fontSize={16}>
            {title ?? 'Sign in with Yahoo'}
        </Typography>
    </AuthButton>)
}