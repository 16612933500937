import * as React from "react"

export const YahooIcon = (props) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <circle cx={12} cy={12} r={12} fill="#6001D2" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m20 5-2.9 7h-3.21l2.875-7H20ZM6.98 8.435l1.772 4.5 1.783-4.5h2.855L8.983 19H6.108l1.187-2.8L4 8.435h2.98ZM12.834 14.5a1.729 1.729 0 0 0 1.752 1.75 1.8 1.8 0 0 0 1.818-1.81 1.724 1.724 0 0 0-1.752-1.755 1.815 1.815 0 0 0-1.818 1.815Z"
            fill="#fff"
        />
    </svg>
)